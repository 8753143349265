<template>
  <div id="app">
    <div class="banner">
      <img :src="this.url.imgUrl + bannerImg" alt="勤学教育" />
    </div>
    <div class="down">
      <div class="login_card">
        <!-- 登录卡片 -->
        <div class="login_box">
          <div class="login">
            <img
              :src="this.url.imgUrl + logoImg"
              alt="勤学教育"
              @click="goHome"
              style="cursor: pointer"
            />
            <div id="login_line"></div>
            <div class="login_right">
              <div>
                <span>账号登录</span
                ><router-link class="home" to="/">返回首页</router-link>
              </div>
              <div class="login_ipt">
                <!-- type="text" -->
                <input
                  type="text"
                  placeholder="账号"
                  v-model="form.account"
                  maxlength="11"
                  @keyup.enter="goLogin"
                />
                <img src="../../assets/img/account.png" alt="勤学教育" />
              </div>
              <div class="login_ipt" style="margin-top: 16px">
                <!-- :type="pwdType" -->
                <input
                  :type="pwdType"
                  placeholder="密码"
                  v-model="form.password"
                  @keyup.enter="goLogin"
                />
                <img src="../../assets/img/password.png" alt="勤学教育" />
                <img
                  :src="eyeType"
                  alt="勤学教育"
                  style="width：22px；height：19px；"
                  @click="changeType()"
                />
              </div>
              <ul style="margin-top: 10px">
                <li>
                  还没有账号？<router-link to="register">注册</router-link>
                </li>
                <li><router-link to="forgetPwd">忘记密码？</router-link></li>
              </ul>
              <!--  -->
              <button type="button" @click="goLogin">立即登录</button>
            </div>
          </div>
        </div>
      </div>
      <div class="record_number">
        备案号：湘ICP备 2023001774号 <br />
        联系地址：长沙市芙蓉区定王台街道五一大道766号中天广场20043 <br />
        联系电话：400-656-1156 服务时间：周一至周五9:00-12:00 14:00-17:00<br />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  data() {
    return {
      bannerImg: "/assets/img/login.png", //banner
      logoImg: "/assets/img/denglu_logo.png", //logo
      form: {
        account: "", //账号
        password: "", //密码
        is_page_id: "", //
      },
      pwdType: "password",
      eyeType: require("../../assets/img/unsee.png"),
      personal_id: "",
    };
  },
  methods: {
    enterToLogin(ev) {
      console.log(123456);
    },
    // 获取地址栏参数
    GetQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    // 登录
    goLogin() {
      const reg = /^1[3-9][0-9]{9}$/;
      if (
        this.form.account == "" ||
        this.form.account <= 10 ||
        !reg.test(this.form.account)
      ) {
        this.$message.error({
          duration: 1500,
          message: "请输入正确账号!",
        });
        return false;
      } else if (this.form.password == "") {
        this.$message.error({
          duration: 1500,
          message: "请输入正确密码!",
        });
        return;
      } else {
        let a = sessionStorage.getItem("logintype");
        api.login(this.form).then((res) => {
          if (parseInt(res.data.code) == 1) {
            localStorage.setItem("token", res.data.data.token);
            this.$message.success({
              duration: 1500,
              message: res.data.msg,
            });
            if (a == 1) {
              this.$router.go(-1);
              sessionStorage.removeItem('logintype')
            } else {
              this.$router.push({ name: "Personal", params: { is_pageid: 8 } });
            }
          } else {
            this.$message.error({
              duration: 1500,
              message: res.data.msg,
            });
          }
        });
      }
    },
    // 去首页
    goHome() {
      // window.location.href = "/pc/index.html?page_id=1";
      this.$router.push({ name: "Index" });
    },
    // 显示密码
    changeType() {
      this.pwdType = this.pwdType === "password" ? "text" : "password";
      this.eyeType =
        this.eyeType === require("../../assets/img/unsee.png")
          ? require("../../assets/img/see.png")
          : require("../../assets/img/unsee.png");
      this.pwd = "password" ? "text" : "password";
    },
  },
  created() {
    var myurl = this.GetQueryString("page_id");
    if (myurl != null && myurl.toString().length > 0) {
      this.personal_id = myurl;
    }
  },
};
</script>

<style lang="scss" scoped>
// @import url("./index.css");
#app .banner {
  width: 100%;
  height: 490px;
  position: relative;
}

#app .banner img {
  width: 100%;
  height: 490px;
  object-fit: cover;
}

#app .down {
  position: relative;
  width: 100%;
  height: 590px;
  /* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) inset; */
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04) inset;
  margin-bottom: 30px;
}

#app .down .login_card {
  position: absolute;
  top: -302px;
  left: 0;
  right: 0;
  width: 1100px;
  height: 529px;
  /* background: pink; */
  margin: auto;
  z-index: 1;
}

#app .down .record_number {
  // width: 511px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  text-align: center;
  margin: auto;
}

.login_card .login_box {
  width: 1100px;
  height: 407px;
  background: #ffffff;
  box-shadow: 0px 0px 35px 0px rgba(173, 192, 238, 0.6);
  border-radius: 20px;
  margin-top: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_card .login_box .login {
  width: 919px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login_card .login_box .login img {
  width: 349px;
  height: 78px;
}

.login_box .login #login_line {
  width: 2px;
  height: 260px;
  background: #e7e7e7;
  /* background: red; */
}

.login_box .login .login_right {
  width: 388px;
}

.login .login_right div:nth-child(1) {
  position: relative;
  // width: 81px;
  width: 100%;
  /* height: 19px; */
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #666666;
  margin: auto;
  margin-bottom: 29px;
  text-align: center;
}
.login .login_right .home {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 14px;
  font-family: PingFang SC;
  color: #3e7eff;
}

.login .login_right .login_ipt {
  position: relative;
}

.login .login_right .login_ipt img {
  position: absolute;
  top: 16px;
  left: 9px;
  width: 18px;
  height: auto;
  /* border: 1px dashed #999999; */
  z-index: 1;
}

.login .login_right .login_ipt img:nth-child(3) {
  position: absolute;
  top: 19px;
  /* left: 9px; */
  /* right: 16px; */
  left: 352px;
  width: 22px;
  height: 19px;
  /* border: 1px dashed #999999; */
  z-index: 1;
}

.login .login_right .login_ipt input {
  width: 353px;
  height: 48px;
  font-size: 16px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding-left: 40px;
  outline: none;
  color: #333;
}

.login_ipt input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999;
  font-size: 16px;
}

.login_ipt input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
  font-size: 16px;
}

.login_ipt input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #999;
  font-size: 16px;
}

.login .login_right ul {
  display: flex;
  justify-content: space-between;
}

.login .login_right ul {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}

.login .login_right ul li a:nth-child(1),
.login .login_right ul li:nth-child(2) {
  color: #3e7eff;
}

.login .login_right button {
  width: 388px;
  height: 48px;
  background: linear-gradient(259deg, #3e7eff 0%, #2765f3 100%);
  box-shadow: 0px 9px 22px 0px rgba(81, 133, 240, 0.8);
  border-radius: 24px;
  outline: none;
  border: none;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 48px;
  margin-top: 33px;
}
</style>
